export const countriess = [
  {
    name: "France",
    phonePrefix: "+2",
  },
  {
    name: "Japan",
    phonePrefix: "+1",
  },
];
